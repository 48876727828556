<template>
  <div class="promise">
    <van-nav-bar left-arrow :title="title" @click-left="$router.back()" />
    <PromiseCalendar
      ref="calendarRef"
      v-model="queryInfo.date"
      class="promise-list__rows"
      @input="onConfirm"
    />
    <div class="search-query">
      <promise-query :query="queryInfo" @refresh="onQueryChange" />
      <div class="search-query__total">共{{ total }}条研判承诺</div>
    </div>
    <van-pull-refresh
      v-model="isPullRefreshing"
      class="promise-list"
      @refresh="onRefresh"
    >
      <van-list
        v-model="isLoading"
        :finished="isFinished"
        :finished-text="isError ? '' : '没有更多了'"
        :error.sync="isError"
        error-text="加载失败，请稍后再试！"
        @load="getList"
      >
        <van-cell
          v-for="(item, idx) in list"
          :key="idx"
          :class="[
            'promise-list__rows',
            idx === list.length - 1 && 'border-none'
          ]"
          :border="false"
          @click="gotoDetails(item)"
        >
          <div class="top">
            <h3 class="title">{{ item.name }}</h3>
            <span
              :class="[
                item.status === 0 ? 'un-promise-color' : 'promised-color'
              ]"
              >{{ item.statusValue }}</span
            >
          </div>
          <div class="bottom">
            <div class="left">
              <div class="left-top">
                <div class="field">
                  <van-icon
                    class-prefix="iconfont"
                    class="list-icon"
                    name="bumen"
                  />
                  <span>{{ item.departmentName }}</span>
                </div>
                <div v-if="item.status === 1" class="field">
                  <van-icon
                    class-prefix="iconfont"
                    class="list-icon"
                    name="chengnuoren"
                  />
                  <span>{{ item.promiseeName }}</span>
                </div>
              </div>
              <div class="left-bottom">
                <div class="field">
                  <van-icon
                    class-prefix="iconfont"
                    class="list-icon"
                    name="riqi"
                  />
                  <span>{{ item.promiseDate }}</span>
                </div>
                <div v-if="item.status === 1" class="field">
                  <van-icon
                    class-prefix="iconfont"
                    class="list-icon"
                    name="chengnuoshijian"
                  />
                  <span>{{ item.promiseTime }}</span>
                </div>
              </div>
            </div>
            <div class="right">
              <van-button
                v-if="item.repromise === 1"
                type="info"
                class="promise-list-button"
                @click.stop="onPromise(item, 0)"
                >承诺</van-button
              >
              <van-button
                v-if="+item.repromise === 2"
                type="info"
                class="promise-list-button"
                @click.stop="onPromise(item, 1)"
                >补录</van-button
              >
            </div>
          </div>
        </van-cell>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { operateDialog, parseTime } from "@/utils";
import PromiseQuery from "@/views/promise/components/PromiseQuery";
import PromiseCalendar from "@/views/promise/components/PromiseCalendar.vue";
import { getPagePromiseList, isPromiseDataPromised } from "@/api/psm";

export default {
  name: "PromiseList",
  components: { PromiseQuery, PromiseCalendar },
  data() {
    return {
      title: "安全风险承诺",
      queryInfo: {
        department: "",
        status: "",
        date: "",
        page: 1,
        size: 10
      },
      total: 0,
      list: [],
      // 下拉刷新，上拉加载
      isPullRefreshing: false,
      isLoading: false,
      isFinished: true,
      isError: false
    };
  },
  created() {
    this.queryInfo.date = parseTime(Date.now(), "{y}-{m}-{d}");
    this.$nextTick(() => {
      this.$refs.calendarRef && this.$refs.calendarRef.onChange(new Date());
    });
  },
  activated() {
    console.log("activated");
  },
  methods: {
    onQueryChange(query) {
      this.queryInfo = { ...this.queryInfo, ...query };
      this.onConfirm();
    },
    onConfirm() {
      // 查询数据
      this.queryInfo.page = 1;
      this.list = [];
      this.getList();
    },
    onRefresh() {
      this.queryInfo.page = 1;
      this.list = [];
      this.getList();
    },
    async getList() {
      this.isFinished = false;
      this.isLoading = true;
      if (this.isPullRefreshing) {
        this.isPullRefreshing = false;
      }
      try {
        const { date, ...query } = this.queryInfo;
        const { list, total } = await getPagePromiseList({
          ...query,
          startDate: date,
          endDate: date
        });
        this.total = total;
        this.isError = false;
        this.isLoading = false;
        if (list && list.length) {
          this.list = this.list.concat(list);
          this.queryInfo.page++;
        } else {
          this.isFinished = true;
        }
      } catch (error) {
        console.log("getList -> error", error);
        this.isLoading = false;
        this.isFinished = true;
        this.isError = true;
      }
    },
    async gotoDetails({ id }) {
      await this.$router.push({ name: "PromiseDetails", params: { id } });
    },
    async onPromise({ id }, replenish = "0") {
      try {
        const isNext = await isPromiseDataPromised(id);
        if (isNext) {
          await operateDialog({
            title: "填报确认",
            message:
              "下级部门未全部完成承诺，请等下级部门全部完成承诺后再进行承诺？",
            showConfirmButton: false
          });
        } else {
          const query = { replenish };
          await this.$router.push({
            name: "PromiseFill",
            params: { id },
            query
          });
        }
      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.promise {
  .search-query {
    &__total {
      margin: 10px 0;
      text-align: center;
      color: #8c8f97;
      font-size: 12px;
      line-height: 17px;
    }
  }
  &-list {
    overflow-y: auto;
    height: calc(100vh - 58px - 62px - 64px);
    .border-none {
      border: none;
    }
    .promised-color {
      color: #8c8f97;
    }
    .un-promise-color {
      color: #ff6d1e;
    }
    &__rows {
      border-bottom: 8px solid #eceef2;
      &:last-of-type {
        border-bottom: none;
      }
      .list-icon {
        color: #aeb3c0;
        font-size: 14px;
        margin-right: 5px;
      }
      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 6px;
        .title {
          font-size: 14px;
          color: #3b4664;
          font-weight: bold;
        }
      }
      .bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left {
          color: #3b4664;
          font-size: 12px;
          .left-top,
          .left-bottom {
            display: flex;
            line-height: 17px;
          }
          .left-top {
            margin-bottom: 5px;
          }
          .field {
            margin-right: 20px;
          }
        }
        .right {
          .promise-list-button {
            width: 72px;
            height: 32px;
            line-height: 35px;
            text-align: center;
            font-size: 14px;
            color: #ffffff;
            background: #1676ff;
            border-radius: 16px;
            .van-button__text {
              height: 32px;
            }
          }
        }
      }
    }
  }
}
</style>
