<template>
  <div class="promise-query">
    <div
      v-for="(item, idx) in configs"
      :key="idx"
      class="promise-query__item"
      @click="onSearchClick(item, idx)"
    >
      <span class="promise-query__item-title">{{ item.title }}</span>
      <van-icon
        name="zhankai"
        class-prefix="iconfont"
        class="promise-query__item-icon"
      />
    </div>
    <cascade-department
      v-model="department"
      :visible="departmentVisible"
      include-children
      @close="departmentVisible = false"
      @confirm="refreshQuery"
    />
    <select-popup
      :choose-value="popupValue"
      :data="popupData"
      :title="popupTitle"
      :visible="popupVisible"
      :popup-style="{
        height: '30vh'
      }"
      @close="onPopupClose"
      @confirm="onPopupConfirm"
    />
  </div>
</template>

<script>
import SelectPopup from "@/components/SelectPopup";
import { promiseStatusTypes } from "@/views/promise/utils/constant";
export default {
  name: "PromiseQuery",
  components: { SelectPopup },
  props: ["query"],
  data() {
    return {
      promiseStatusTypes,
      department: "",
      queryInfo: {
        department: "",
        subDepartment: true,
        status: ""
      },
      configs: [
        {
          title: "承诺部门",
          type: "department"
        },
        {
          title: "承诺状态",
          type: "status",
          types: "promiseStatusTypes"
        }
      ],
      departmentVisible: false,
      popupVisible: false,
      popupTitle: "",
      popupData: [],
      selectValue: "",
      popupValue: "",
      popupType: ""
    };
  },
  watch: {
    "queryInfo.department"(val) {
      console.log("val", val);
    }
  },
  methods: {
    refreshQuery(selectValue, includeInfo) {
      this.queryInfo.department = this.department;
      this.queryInfo.subDepartment = includeInfo?.include;
      this.$emit("refresh", this.queryInfo);
    },
    onSearchClick({ title, type, types }, idx) {
      console.log(title, type, types, idx);
      if (type === "department") {
        // this.selectValue = this.queryInfo.department;
        this.departmentVisible = true;
      } else {
        this.popupValue = this.queryInfo[type] || "";
        this.popupType = type;
        this.popupData = this[types];
        this.popupTitle = title;
        this.popupVisible = true;
      }
    },
    onPopupReset() {
      this.queryInfo[this.popupType] = "";
      this.popupValue = "";
      this.refreshQuery();
    },
    onPopupClose() {
      this.popupVisible = false;
      this.popupData = [];
    },
    onPopupConfirm(row) {
      console.log(row, "row");
      this.queryInfo[this.popupType] = row.value;
      this.refreshQuery();
    }
  }
};
</script>

<style lang="scss">
.promise-query {
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;
  margin-top: -2px;
  color: #3b4664;
  font-size: 14px;
  position: relative;
  &:after {
    position: absolute;
    bottom: 0;
    content: "";
    height: 1px;
    width: 100%;
    left: 0;
    border-bottom: 0.5px solid #c7c9d0;
    transform: scaleY(0.5);
  }
  &__item {
    flex: 1;
    display: flex;
    /*justify-content: space-between;*/
    justify-content: center;
    align-items: center;
    &-icon {
      margin-left: 4px;
      color: #aeb3c0;
      font-size: 12px;
    }
  }
}
</style>
