<template>
  <div class="promise-calendar">
    <van-calendar
      :key="year"
      ref="calendar"
      class="promise-calendar-calendar"
      v-bind="$attrs"
      :default-date="defaultDate"
      :poppable="false"
      :show-confirm="false"
      :show-subtitle="false"
      :min-date="minDate"
      :max-date="maxDate"
      v-on="$listeners"
      @select="onChange"
      @month-show="onShowMonth"
    >
      <template #top-info="day">
        <div class="promise-calendar-cell" :style="getStyle(day.date)">
          {{ getDay(day) }}
        </div>
      </template>
      <template #title>
        <div class="promise-calendar-header">
          <van-icon
            name="iconfont-a-bianzu3"
            class-prefix="iconfont"
            class="iconfont-a-bianzu3 promise-calendar-icon"
            size="5"
            @click="preYear"
          />
          <van-icon
            name="iconfont-Linebeifen"
            class-prefix="iconfont"
            class="iconfont-Linebeifen promise-calendar-icon"
            size="5"
            @click="preMonth"
          />
          <span class="promise-calendar-title">{{ date }}</span>
          <van-icon
            name="iconfont-Line1"
            class-prefix="iconfont"
            class="iconfont-Line1 promise-calendar-icon"
            size="5"
            @click="nextMonth"
          />
          <van-icon
            name="iconfont-a-bianzu2"
            class-prefix="iconfont"
            class="iconfont-a-bianzu2 promise-calendar-icon"
            size="5"
            @click="nextYear"
          />
        </div>
      </template>
      <template #footer>
        <div class="promise-calendar-status-list">
          <div
            v-for="(item, index) in statusList"
            :key="index"
            class="promise-calendar-status-item"
          >
            <span class="promise-calendar-status-dot" :style="item.dotStyle" />
            <span class="promise-calendar-status-label">{{ item.label }}</span>
          </div>
        </div>
      </template>
    </van-calendar>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { getCalendarStatusList } from "@/api/psm";
import debounce from "lodash/debounce";

const statusList = [
  {
    type: "0",
    label: "全部未完成",
    cellStyle: { background: "rgba(245, 95, 95, 0.15)", color: "#F55F5F" },
    dotStyle: { background: "#F55F5F", border: "1px solid #F55F5F" }
  },
  {
    type: "1",
    label: "部分未完成",
    cellStyle: { background: "rgba(243, 150, 28, 0.15)", color: "#F3961C" },
    dotStyle: { background: "#F3961C", border: "1px solid #F3961C" }
  },
  {
    type: "2",
    label: "全部已完成",
    cellStyle: { color: "#64AE08" },
    dotStyle: { background: "#64AE08", border: "1px solid #64AE08" }
  }
];

export default {
  name: "PromiseCalendar",
  props: {
    value: {
      type: String
    },
    calendarStatus: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      calendarMap: new Map(),
      // 记录已经曝光的月份
      exposureMonths: new Set(),
      // 正在曝光的月份
      exposureMonthsList: []
    };
  },
  computed: {
    // 使用年份做份日历的key值，解决vant日历的更换年，日期不曝光的问题
    year() {
      return new Date(this.value).getFullYear();
    },
    minDate() {
      return dayjs(this.value)
        .subtract(3, "month")
        .startOf("month").$d;
    },
    maxDate() {
      return dayjs(this.value)
        .add(6, "month")
        .endOf("month").$d;
    },
    defaultDate() {
      return this.value && new Date(this.value);
    },
    date() {
      return dayjs(this.value).format("YYYY年M月");
    },
    statusList() {
      return statusList;
    },
    statusMap() {
      return new Map(this.statusList.map(item => [item.type, item]));
    }
  },
  mounted() {
    // 阻止滑动切换月份
    // const calendarBody = this.$refs.calendar.$el.querySelector('.van-calendar__body')
    // calendarBody && calendarBody.addEventListener('touchmove', (e) => e.preventDefault())
  },
  methods: {
    getDay(day) {
      if (!day.date) return "";
      return dayjs(day.date).format("D");
    },
    onChange(date) {
      const val = dayjs(date).format("YYYY-MM-DD");
      this.$emit("input", val);
    },
    onShowMonth({ date }) {
      this.exposureMonth(
        dayjs(date)
          .startOf("month")
          .format("YYYY-MM-DD")
      );
    },
    // 延迟曝光减少请求次数
    exposureMonth(date) {
      this.exposureMonthsList.push(date);
      this.updateCalendarStatus();
    },
    updateCalendarStatus: debounce(function() {
      const newMonth = [];
      this.exposureMonthsList.forEach(item => {
        // 已经曝光的月份不用管了
        if (!this.exposureMonths.has(item)) {
          this.exposureMonths.add(item);
          newMonth.push(dayjs(item).valueOf());
        }
      });
      this.exposureMonthsList = [];
      // 没有新增月份曝光直接返回
      if (!newMonth.length) return;
      const min = Math.min(...newMonth);
      const max = Math.max(...newMonth);
      const startDate = dayjs(min)
        .startOf("month")
        .format("YYYY-MM-DD");
      const endDate = dayjs(max)
        .endOf("month")
        .format("YYYY-MM-DD");

      getCalendarStatusList({ startDate, endDate }).then(res => {
        (res || []).forEach(item => {
          this.calendarMap.set(item.date, item.type);
        });
        this.calendarMap = new Map([...this.calendarMap]);
      });
    }, 100),
    getStyle(date) {
      const day = dayjs(date).format("YYYY-MM-DD");
      const type = this.calendarMap.get(day);
      const cell = this.statusMap.get(type) || {};
      return cell.cellStyle;
    },
    preYear() {
      const val = dayjs(this.value)
        .subtract(1, "year")
        .format("YYYY-MM-DD");
      this.$emit("input", val);
    },
    preMonth() {
      const val = dayjs(this.value)
        .subtract(1, "month")
        .format("YYYY-MM-DD");
      this.exposureMonth(
        dayjs(val)
          .startOf("month")
          .format("YYYY-MM-DD")
      );
      this.$emit("input", val);
    },
    nextMonth() {
      const val = dayjs(this.value)
        .add(1, "month")
        .format("YYYY-MM-DD");
      this.exposureMonth(
        dayjs(val)
          .startOf("month")
          .format("YYYY-MM-DD")
      );
      this.$emit("input", val);
    },
    nextYear() {
      const val = dayjs(this.value)
        .add(1, "year")
        .format("YYYY-MM-DD");
      this.$emit("input", val);
    }
  }
};
</script>

<style lang="scss" scoped>
.van-calendar__selected-day {
  .promise-calendar-cell {
    border: 1px solid #1676ff;
  }
}
.promise-calendar {
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
  height: 340px;

  &-header {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #aeb3c0;
    .promise-calendar-icon {
      padding: 0 10px;
    }
  }

  &-title {
    padding: 0 10px;
    font-size: 16px;
    font-family: PingFang-SC-Heavy, PingFang-SC;
    font-weight: 800;
    color: #3b4664;
  }

  &-status-list {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 14px 0 20px 0;
  }

  &-status-item {
    display: flex;
    align-items: center;
  }

  &-status-dot {
    position: relative;
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 12px;
    box-sizing: border-box;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      display: inline-block;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      border-radius: 50%;
      border: 2px solid #fff;
    }
  }

  &-status-label {
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #99a3af;
    margin-left: 10px;
  }

  & ::v-deep {
    .van-calendar__top-info {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .promise-calendar-cell {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 15px;
      font-family: DIN-Medium, DIN;
      font-weight: 500;
      line-height: 18px;
    }
    .van-calendar__selected-day {
      width: 100%;
      height: 100%;
      background: transparent;
      color: unset;
    }
    .van-calendar__day {
      position: relative;
      height: 40px;
      box-sizing: border-box;
      border: 1px solid transparent;
      font-size: 0;
      color: unset;
    }
    .van-calendar__header {
      padding-bottom: 10px;
      box-shadow: none;
    }
    .van-calendar__weekday {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #909399;
    }
    .van-calendar__month-title {
      display: none;
    }
  }
}
</style>
