import { deepFreeze } from "@/utils";

export const promiseStatusTypes = deepFreeze([
  {
    label: "未承诺",
    value: "0"
  },
  {
    label: "已承诺",
    value: "1"
  }
]);

export const controlDic = deepFreeze({
  1: "受控",
  0: "不受控"
});

export const promiseDetailsConf = deepFreeze([
  // 装置的安全状态
  {
    type: "equipmentSafeVO",
    title: "装置的安全状态",
    children: [
      {
        label: "共计生产装置",
        prop: "equipmentNum"
      },
      {
        label: "运行生产装置",
        prop: "runNum"
      },
      {
        label: "停产生产装置",
        prop: "suspendNum"
      },
      {
        label: "检修生产装置",
        prop: "unitsNum"
      }
    ]
  },
  // 生产装置的安全运行状态
  {
    type: "equipmentSafeRunVO",
    title: "生产装置的安全运行状态",
    children: [
      {
        label: "工艺参数是否处于指标范围",
        type: "yesOrNo",
        prop: "parameterStatus"
      },
      {
        label: "特种设备是否安全运行",
        type: "yesOrNo",
        prop: "runStatus"
      },
      {
        label: "设备设施密封是否完好无泄漏",
        type: "yesOrNo",
        prop: "leakStatus"
      },
      {
        label: "安全设施是否配备完好可运行",
        type: "yesOrNo",
        prop: "useStatus"
      },
      {
        label: "液氨库存",
        prop: "nhStock",
        precision: 3
      },
      {
        label: "柴油库存",
        prop: "dieselStock",
        precision: 3
      },
      {
        label: "甲醇库存",
        prop: "methanolStock",
        precision: 3
      },
      {
        prop: "productionScheme",
        type: "yesOrNo",
        label:
          "装置开停车是否制定开停车方案，试生产是否制定试生产方案并经专家论证"
      },
      {
        prop: "protectiveDevice",
        type: "yesOrNo",
        label:
          "各类保护装置的完整性、可靠性检查，包括继电保护装置的校验、整定记录、避雷针、避雷器的保护范围，技术参数，接地装置是否符合规程要求，各种保护接地、接零是否正确可靠，是否合格"
      },
      {
        prop: "protectiveFacility",
        type: "yesOrNo",
        label:
          "变压器室、配电室、电容器室等，是否设置有防止雨、雪和蛇、鼠类小动物从采光窗、通风窗、门、电缆沟进入室内的设施"
      },
      {
        prop: "electricalCertification",
        type: "yesOrNo",
        label:
          "电气装置是否具有国家指定机构的安全认证标志或其安全性能已经经过国家制定的检验机构检验合格"
      }
    ]
  },
  // 涉及灌区、仓库等重大危险源的安全运行状态
  {
    type: "poiSafeRunVO",
    title: "涉及罐区、仓库等重大危险源的安全运行状态",
    children: [
      {
        label: "仪表系统是否完好无泄漏",
        type: "yesOrNo",
        prop: "poiLeakStatus"
      },
      {
        label: "储罐参数是否无超限运行",
        type: "yesOrNo",
        prop: "poiLimitStatus"
      },
      {
        label: "浮盘是否可能落底",
        type: "yesOrNo",
        prop: "poiFallStatus"
      },
      {
        label: "是否确保人员在岗",
        type: "yesOrNo",
        prop: "poiJobStatus"
      },
      {
        label: "报警装置是否可靠运行",
        type: "yesOrNo",
        prop: "poiRunStatus"
      },
      {
        label: "仓库是否符合国标",
        type: "yesOrNo",
        prop: "poiStorageStatus"
      },
      {
        label: "车辆安全是否受控",
        type: "yesOrNo",
        prop: "poiVehicleStatus"
      }
    ]
  },
  // 高危生产活动及作业的安全风险可控状态
  {
    type: "workSafeVO",
    title: "高危生产活动及作业的安全风险可控状态",
    children: [
      {
        label: "特级动火作业",
        prop: "firesWorkNum"
      },
      {
        label: "一级动火作业",
        prop: "fire1WorkNum"
      },
      {
        label: "二级动火作业",
        prop: "fire2WorkNum"
      },
      {
        label: "受限空间作业",
        prop: "spaceWorkNum"
      },
      {
        label: "盲板抽堵作业",
        prop: "blindplateWorkNum"
      },
      {
        label: "临时用电作业",
        prop: "temporaryElectricityNum"
      },
      {
        label: "高处作业",
        prop: "highWorkNum"
      },
      {
        label: "吊装作业",
        prop: "hoistingNum"
      },
      {
        label: "动土作业",
        prop: "soilWorkNum"
      },
      {
        label: "断路作业",
        prop: "roadWorkNum"
      },
      {
        label: "检维修作业",
        prop: "inspectionWorkNum"
      },
      {
        label: "其它特殊作业",
        prop: "workOtherNum"
      },
      {
        label: "管线打开作业",
        prop: "pipelineWorkNum"
      },
      {
        label: "特殊作业是否符合程序",
        type: "yesOrNo",
        prop: "implement"
      },
      {
        label: "是否处于试生产",
        type: "yesOrNo",
        prop: "trialProduction"
      },
      {
        label: "是否处于开停车状态",
        type: "yesOrNo",
        prop: "openParking"
      },
      {
        label: "重大危险源是否安全",
        type: "yesOrNo",
        prop: "safe"
      },
      {
        label: "是否有承包商作业",
        type: "yesOrNo",
        prop: "contractor"
      },
      // 受控不受控
      {
        label: "承包商作业管控情况",
        type: "controlDic",
        prop: "control"
      },

      {
        label: "施工作业现场是否安全",
        type: "yesOrNo",
        prop: "liveSafe"
      },
      {
        label: "是否存在变更情况",
        type: "yesOrNo",
        prop: "changeStatus"
      },
      {
        label: "是否落实审批程序",
        type: "yesOrNo",
        prop: "approve"
      },
      {
        prop: "workManagement",
        type: "yesOrNo",
        label:
          "各项特殊作业，检维修作业、承包商作业是否健全和完善相关管理制度，作业过程是否进行安全风险辨识，严格程序确认和作业许可审批，加强现场监督，危险化学品罐区动火作业是否做到升级管理等"
      }
    ]
  },
  // 其它状态
  {
    type: "othersVO",
    title: "其它状态",
    children: [
      {
        label: "是否落实风控措施",
        type: "yesOrNo",
        prop: "risk"
      },
      {
        label: "是否落实隐治措施",
        type: "yesOrNo",
        prop: "trouble"
      },
      {
        prop: "fireEquipment",
        type: "yesOrNo",
        label: "消防器材有无损坏、是否缺失"
      },
      {
        prop: "changeApproval",
        type: "yesOrNo",
        label: "各项变更的审批程序是否符合规定"
      },
      {
        prop: "electricitySafety",
        type: "yesOrNo",
        label:
          "是否有私拉乱接的临时线，临时灯。生产需要应办理临时用电是否申请手续，定期检查，过期拆除"
      },
      {
        prop: "placeLighting",
        type: "yesOrNo",
        label:
          "主要通道及主要出入口、通道楼梯、操作室、计算机室、主电室、配电室、泵房、电缆隧道等场所的一般事故照明是否完好"
      },
      {
        prop: "electricalProtection",
        type: "yesOrNo",
        label:
          "临时性及移动设备的供电，是否采用漏电流动作保护器作为附加保护措施；爆炸危险场所是否按要求选用相应级别的防爆型电气设备"
      }
    ]
  }
]);

// 填报承诺
export const promiseFillConf = deepFreeze([
  {
    label: "运行生产装置",
    key: "runNum"
  },
  {
    label: "停产生产装置",
    key: "suspendNum"
  },
  {
    label: "检修生产装置",
    key: "unitsNum"
  },
  {
    label: "特级动火作业",
    key: "firesWorkNum"
  },
  {
    label: "一级动火作业",
    key: "fire1WorkNum"
  },

  {
    label: "二级动火作业",
    key: "fire2WorkNum"
  },
  {
    label: "受限空间作业",
    key: "spaceWorkNum"
  },
  {
    label: "盲板抽堵作业",
    key: "blindplateWorkNum"
  },
  {
    label: "临时用电作业",
    key: "temporaryElectricityNum"
  },
  {
    label: "高处作业",
    key: "highWorkNum"
  },
  {
    label: "吊装作业",
    key: "hoistingNum"
  },
  {
    label: "动土作业",
    key: "soilWorkNum"
  },
  {
    label: "断路作业",
    key: "roadWorkNum"
  },
  {
    label: "检维修作业",
    key: "inspectionWorkNum"
  },
  {
    label: "其它特殊作业",
    key: "workOtherNum"
  },
  {
    label: "管线打开作业",
    key: "pipelineWorkNum"
  },
  {
    label: "液氨库存",
    key: "nhStock",
    precision: 3
  },
  {
    label: "柴油库存",
    key: "dieselStock",
    precision: 3
  },
  {
    label: "甲醇库存",
    key: "methanolStock",
    precision: 3
  },
  {
    key: "productionScheme",
    type: "dic",
    label: "装置开停车是否制定开停车方案，试生产是否制定试生产方案并经专家论证"
  },
  {
    key: "protectiveDevice",
    type: "dic",
    label:
      "各类保护装置的完整性、可靠性检查，包括继电保护装置的校验、整定记录、避雷针、避雷器的保护范围，技术参数，接地装置是否符合规程要求，各种保护接地、接零是否正确可靠，是否合格"
  },
  {
    key: "protectiveFacility",
    type: "dic",
    label:
      "变压器室、配电室、电容器室等，是否设置有防止雨、雪和蛇、鼠类小动物从采光窗、通风窗、门、电缆沟进入室内的设施"
  },
  {
    key: "electricalCertification",
    type: "dic",
    label:
      "电气装置是否具有国家指定机构的安全认证标志或其安全性能已经经过国家制定的检验机构检验合格"
  },
  {
    label: "工艺参数是否处于指标范围",
    type: "dic",
    key: "parameterStatus"
  },
  {
    label: "特种设备是否安全运行",
    type: "dic",
    key: "runStatus"
  },
  {
    label: "设备设施密封是否完好无泄漏",
    type: "dic",
    key: "leakStatus"
  },
  {
    label: "安全设施是否配备完好可运行",
    type: "dic",
    key: "useStatus"
  },
  {
    label: "仪表系统是否完好无泄漏",
    type: "dic",
    key: "poiLeakStatus"
  },
  {
    label: "储罐参数是否无超限运行",
    type: "dic",
    key: "poiLimitStatus"
  },
  {
    label: "浮盘是否可能落底",
    type: "dic",
    key: "poiFallStatus"
  },
  {
    label: "是否确保人员在岗",
    type: "dic",
    key: "poiJobStatus"
  },
  {
    label: "报警装置是否可靠运行",
    type: "dic",
    key: "poiRunStatus"
  },
  {
    label: "仓库是否符合国标",
    type: "dic",
    key: "poiStorageStatus"
  },
  {
    label: "车辆安全是否受控",
    type: "dic",
    key: "poiVehicleStatus"
  },
  {
    label: "特殊作业是否符合程序",
    type: "dic",
    key: "implement"
  },
  {
    label: "是否处于试生产",
    type: "dic",
    key: "trialProduction"
  },
  {
    label: "是否处于开停车状态",
    type: "dic",
    key: "openParking"
  },
  {
    label: "重大危险源是否安全",
    type: "dic",
    key: "safe"
  },
  {
    label: "是否有承包商作业",
    type: "dic",
    key: "contractor"
  },
  // 特殊处理
  {
    label: "承包商作业管控情况",
    type: "controlDic",
    key: "control"
  },
  {
    label: "施工作业现场是否安全",
    type: "dic",
    key: "liveSafe"
  },
  {
    label: "是否存在变更情况",
    type: "dic",
    key: "changeStatus"
  },
  {
    label: "是否落实审批程序",
    type: "dic",
    key: "approve"
  },
  {
    key: "workManagement",
    type: "dic",
    label:
      "各项特殊作业，检维修作业、承包商作业是否健全和完善相关管理制度，作业过程是否进行安全风险辨识，严格程序确认和作业许可审批，加强现场监督，危险化学品罐区动火作业是否做到升级管理等"
  },
  {
    label: "是否落实风控措施",
    type: "dic",
    key: "risk"
  },
  {
    label: "是否落实隐治措施",
    type: "dic",
    key: "trouble"
  },
  {
    key: "fireEquipment",
    type: "dic",
    label: "消防器材有无损坏、是否缺失"
  },
  {
    key: "changeApproval",
    type: "dic",
    label: "各项变更的审批程序是否符合规定"
  },
  {
    key: "electricitySafety",
    type: "dic",
    label:
      "是否有私拉乱接的临时线，临时灯。生产需要应办理临时用电是否申请手续，定期检查，过期拆除"
  },
  {
    key: "placeLighting",
    type: "dic",
    label:
      "主要通道及主要出入口、通道楼梯、操作室、计算机室、主电室、配电室、泵房、电缆隧道等场所的一般事故照明是否完好"
  },
  {
    key: "electricalProtection",
    type: "dic",
    label:
      "临时性及移动设备的供电，是否采用漏电流动作保护器作为附加保护措施；爆炸危险场所是否按要求选用相应级别的防爆型电气设备"
  }
]);
